'use client';
import { Grid } from '@mui/material';
import { Container } from '@mui/system';
import dynamic from 'next/dynamic';

const AboutSection = dynamic(
  () =>
    import('../../components/Sections').then((module) => module.AboutSection),
  { ssr: false }
);
const BusinessLines = dynamic(
  () =>
    import('../../components/Sections').then((module) => module.BusinessLines),
  { ssr: false }
);
const OurProducts = dynamic(
  () =>
    import('../../components/Sections').then((module) => module.OurProducts),
  { ssr: false }
);
const OurServices = dynamic(
  () =>
    import('../../components/Sections').then((module) => module.OurServices),
  { ssr: false }
);
const OurTechnologies = dynamic(
  () =>
    import('../../components/Sections').then(
      (module) => module.OurTechnologies
    ),
  { ssr: false }
);
const OurClients = dynamic(
  () => import('../../components/Sections').then((module) => module.OurClients),
  { ssr: false }
);
const Hero = dynamic(() => import('../../components/Hero'), { ssr: false });
const SectionImageRotate = dynamic(
  () => import('../../components/SectionImageRotate'),
  { ssr: false }
);
const FooterWrapper = dynamic(() => import('../../components/FooterWrapper'), {
  ssr: false,
});
const FooterInfo = dynamic(() => import('../../components/FooterInfo'), {
  ssr: false,
});
export default function Home({
  params: { lng },
}: {
  params: {
    lng: 'es' | 'en';
  };
}) {
  return (
    <Container maxWidth={false} disableGutters>
      <Grid container>
        <Grid item xs={12}>
          <Hero lng={lng} />
        </Grid>
        <Grid item xs={12}>
          <AboutSection lng={lng} />
        </Grid>
        <Grid item xs={12}>
          <BusinessLines lng={lng} />
        </Grid>

        <Grid item xs={12}>
          <SectionImageRotate>
            <OurProducts lng={lng} />
            <OurServices lng={lng} />
          </SectionImageRotate>
        </Grid>

        <Grid item xs={12}>
          <OurClients lng={lng} />
        </Grid>

        <Grid item xs={12}>
          <FooterWrapper>
            <OurTechnologies lng={lng} />
            <FooterInfo lng={lng} />
          </FooterWrapper>
        </Grid>
      </Grid>
    </Container>
  );
}
